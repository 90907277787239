import React from 'react'

import { Checkbox, Form, Input, Button, Message, Select, TextArea } from 'semantic-ui-react'
import * as Theme from '../Elements/theme'

import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const destinationType = [
  { key: 'XS', text: 'XS', value: 'XS' },
  { key: 'S', text: 'S', value: 'S' },
  { key: 'M', text: 'M', value: 'M' },
  { key: 'L', text: 'L', value: 'L' },
  { key: 'XL', text: 'XL', value: 'XL' },
]

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isValidated: false,
      Imię_i_Nazwisko: this.props.props[0] !== undefined ? this.props.props[0].name : this.props.props.name,
      Numer_Telefonu: '',
      Adres_email: 'm#m',
      Nazwa_Szkoły: '',
      Numer_PESEL: '',
      Ilu_uczęstników: '',
      Wiek_Klasa: '',
      Ilu_nauczycieli: '',
      Proponowany_termin: '',
      Liczba_dni: '',
      Sugerowane_miejsce: '',
      Charakter_wyjazdu: '',
      Dodatkowe_informacje: '',
      privacyCheck: false,
      errMessage: false
     };
  }
  
  componentDidMount() {
    // console.log(this.props.props);
    
  }
  

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = () => {
    this.setState({ 
      privacyCheck: !this.state.privacyCheck,
      errMessage: !this.state.errMessage,
     })
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.privacyCheck === true) {
      const form = e.target;
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error));
    } else {
      this.setState({ 
        errMessage: true
       })
    }
  };

  render() {
    // console.log('in form', this.props.props.Rozmiar_Koszulki);
    
    const { props } = this.props

    return (
      <>
            {/* <Button color='blue' onClick={this.props.testclick(this.state.Adres_email)}>
TESTIT
</Button> */}
      <Form 
        name="Rezerwacja"
        method="post"
        action="/dziekujemy/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        style={{ fontFamily: Theme.FontFamily.SubHeading, fontSize: Theme.Font.Size.Form, fontWeight: 400, marginTop: '5rem' }}
      >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
       <input type="hidden" name="form-name" value="contact"/>
       <div hidden>
         <label>
           Don’t fill this out:{" "}
           <input name="bot-field" onChange={this.handleChange} />
         </label>
       </div>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Imię i Nazwisko dziecka'
            name='Imie_i_Nazwisko'
            // onChange={this.props.handleChange(this.state.Imię_i_Nazwisko)}
            onChange={e => this.props.handleChange(e)}
            required
            autoFocus
            type='text'
            value={ this.props.props[0] !== undefined ? props[0].Imie_i_Nazwisko : props.Imie_i_Nazwisko }
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Data Urodzenia Dziecka'
            name='Data_Urodzenia'
            onChange={e => this.props.handleChange(e)}
            required
            type='date'
            value={ props[0] !== undefined ? props[0].Data_Urodzenia : props.Data_Urodzenia }
          />
          <Form.Field
            control={Input}
            label='Numer PESEL Dziecka'
            name='Numer_PESEL'
            onChange={e => this.props.handleChange(e)}
            required
            type='text'
            value={ props[0] !== undefined ? props[0].Numer_PESEL : props.Numer_PESEL }
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Adres Zamieszkania Dziecka'
            name='Adres_Dziecka'
            onChange={e => this.props.handleChange(e)}
            required
            type='text'
            value={ props[0] !== undefined ? props[0].Adres_Dziecka : props.Adres_Dziecka }
          />
          <Form.Field
            control={Input}
            label='Numer Telefonu'
            name='Numer_Telefonu'
            onChange={e => this.props.handleChange(e)}
            required
            type='text'
            value={ props[0] !== undefined ? props[0].Numer_Telefonu : props.Numer_Telefonu }
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Select}
            label='Rozmiar Koszulki'
            name='Rozmiar_Koszulki'
            onChange={e => this.props.handleSelect(e)}
            options={destinationType}
            required
            value={ props[0] !== undefined ? props[0].Rozmiar_Koszulki : props.Rozmiar_Koszulki }
          />
        </Form.Group>
        <Form.Field
          control={TextArea}
          rows={10}
          label='Dodatkowe informacje'
          name='Dodatkowe_informacje'
          onChange={e => this.props.handleChange(e)}
        />
        {/* <Button 
          color='teal'
          style={{ fontFamily: Theme.FontFamily.SubHeading, fontSize: Theme.Font.Size.Contact, fontWeight: 400 }}
          type='submit'
          >Wyślij
        </Button> */}
        <Message negative hidden={!this.state.errMessage}>
          <Message.Header>Wystąpił błąd</Message.Header>
          <p>Prosimy o wyrażenie zgody na kontakt przed wysłaniem formularza.</p>
        </Message>
      </Form>

      </>
    )
  }
}