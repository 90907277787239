import React, { useState, useEffect, useRef } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'
import ReservationForm from '../components/ReservationForm'
import CTASection from '../components/CTA Section'

import { ContentWrapper, Header } from '../components/Elements'
import { Button, Checkbox, Form, Grid, Icon, Message, Table } from 'semantic-ui-react'

const ReservationPage = (props) => {

  const newUser =
  {
    Imie_i_Nazwisko: '',
    Data_Urodzenia: '',
    Numer_PESEL: '',
    Adres_Dziecka: '',
    Numer_Telefonu: '',
    Rozmiar_Koszulki: '',
    allDataFilled: false,
    Dodatkowe_informacje: '',
  }

  const initialState = [newUser] 
  const [ activeIndex, setActiveIndex ] = useState(0)
  const [ participants, setParticipants ] = useState(initialState)
  const [ resState, setResState ] = useState({ privacyCheck: false, error: false })

  useEffect(() => {
    setActiveIndex(activeIndex)
  }, [participants])

  const addUserRow = async () => {
    setParticipants(participants.concat(newUser))
    setActiveIndex(participants.length)
  }

  const handleAddUser = async () => {
    await addUserRow()
    let allRows = document.querySelectorAll('tr')
    allRows.forEach(e => {
      e.classList.remove('active')
    })
    allRows[allRows.length - 2].classList.add('active')
  }

  const handleEditUser = (e) => {
    if (e.target.getAttribute('data-function') !== 'remove') {
      
      let allRows = document.querySelectorAll('tr')
          allRows.forEach(e => e.classList.remove('active'))

      let nodeKey = Number(e.target.parentNode.getAttribute('data-rkey'))
      if (activeIndex === nodeKey) {
        e.target.parentNode.classList.remove('active')
        setActiveIndex(-1)
      } else {
        e.target.parentNode.classList.add('active')
        setActiveIndex(nodeKey)
      }
    }
  }

  const handleRemoveUser = (e) => {
    let participantEntries = participants.entries()
    let remainingRows = []
    let tableRow = e.target.parentNode.parentNode.getAttribute('data-rkey')
    setActiveIndex(-1)
    for (let e of participantEntries) {
      if (e[0] != tableRow) {
        remainingRows.push(e[1])
      }
    }
    setParticipants(remainingRows)
  }

  const handleChange = (data) => {
    setParticipants(updateParticipants(data.target.name, data.target.value))
  }

  const handleSelect = (data) => {

    let name = data.target.parentNode.parentNode.getAttribute('name')
    let values = data.target.parentNode.parentNode
    let value = values.firstChild.innerHTML

    // values.forEach(e => {

    //   if (e.classList.contains(`text`)) {
    //     console.log('e, e.text', e, e.innerHTML)
    //     value = e.innerHTML
    //   }

    // })

    console.log('data.target.name, data.target.value', data.target.parentNode.parentNode.firstChild, name, value)

    setParticipants(updateParticipants(name, value))
  }

  const updateParticipants = (name, value) => {
    let participantList = [] 
    participants.map((item, index) => {
      if (index == activeIndex) {
        item[name] = value
      } 
      participantList = [...participantList, ...item]
    })
    return participantList
  }

  const handleCheck = () => {
    setResState({ 
      privacyCheck: !resState.privacyCheck,
      error: !resState.error,
     })
  }

  const handleSendReservation = () => {

    resState.privacyCheck ? 



    console.log(`Rejestracja wyslana`)

    : setResState({ error: true })
    
    //* save to db, if successfull:
        //* clear local storage
        //* navigate to thankyou page
      //* else error

  }

  return (
    <>
      <ContentWrapper>
        <Grid centered verticalAlign='middle' style={{ fontFamily: 'Playfair Display'}}>
          <Grid.Row>
            <Grid.Column largeScreen={14} computer={14} tablet={10} mobile={14} padded='vertically'>
              <Header title='true' spaced='true'>Rezerwacja</Header>
              <Header>  </Header>
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Imię i Nazwisko</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Data Urodzenia</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Wszystkie Dane Uzupełnione?</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center' collapsing>Usuń</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { participants.map(( child, index ) => {
                    return (
                      <Table.Row key={ index } data-rkey={ index } onClick={ handleEditUser }>
                        <Table.Cell>{ child.Imie_i_Nazwisko }</Table.Cell>
                        <Table.Cell textAlign='center'>{ child.Data_Urodzenia }</Table.Cell>
                        <Table.Cell textAlign='center'>{ child.allDataFilled ? `Tak` : `Nie` }</Table.Cell>
                        <Table.Cell data-function='remove' textAlign='center'>
                          <Icon data-function='remove' name='times' size='large' color='red' onClick={ handleRemoveUser }/>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan='4'>
                      <Button floated='left' icon labelPosition='left' primary size='small' onClick={ handleAddUser }>
                        <Icon name='user' /> Dodaj uczestnika
                      </Button>
                    {/* </Table.HeaderCell>
                    <Table.HeaderCell colSpan='4'> */}
                      {/* <Button floated='left' icon labelPosition='left' primary size='small' onClick={ handleAddUser }>
                        <Icon name='user' /> Zapisz na później
                      </Button>
                    </Table.HeaderCell> */}
                    {/* <Table.HeaderCell colSpan='4'> */}
                      <Button floated='right' icon labelPosition='right' color='teal' size='small' onClick={ handleSendReservation }>
                        <Icon name='send' /> Wyślij rezerwacje
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
              <Form>
                <Form.Field>
                  <Checkbox 
                    label='Wyrażam zgodę na kontakt mailowy lub telefoniczny ze strony pracownika odpowiednich działów Kapiteam.'
                    checked={resState.privacyCheck}
                    onChange={ handleCheck }
                  />
                </Form.Field>
                <Message negative hidden={!resState.error}>
                  <Message.Header>Wystąpił błąd</Message.Header>
                  <p>Prosimy o wyrażenie zgody na kontakt przed wysłaniem formularza.</p>
                </Message>
              </Form>
              { activeIndex != -1 ? 
                  <ReservationForm key={activeIndex} props={ participants[activeIndex] } 
                    handleChange={ handleChange } handleSelect={ handleSelect } 
                    />
                : null }              
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentWrapper>
    </>
  )
}

export default () => (
  <Layout>
    <SEO title='Rezerwacja' />
      <ReservationPage />
    <CTASection />
  </Layout>
)